import { Component, ElementRef, ViewChild, TemplateRef, OnDestroy, OnInit } from '@angular/core';
import { LayoutsComponent, UtilService } from '../../main/index';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { MatDrawer, MatSidenav } from '@angular/material/sidenav';
import { EmailsService } from '../../../_services/emails.service';
import { environment } from '../../../../environments/environment';
import { GoogleAnalyticsService } from '../../../_services/google-analytics.service';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { animation } from 'app/shared/utils/animation-custom';
import { BreakpointObserver } from '@angular/cdk/layout';
import { CustomerService } from 'app/shared/customer.service';
import { SessionService } from './session.service';
import { CreateCaseDialogService } from 'app/shared/core-lib-modal-services/cases/create-case-dialog.service';
import { Select, Store } from '@ngxs/store';
import { CustomersState } from 'app/states/customers/customers.state';
import { AdminCustomerService } from 'app/admin/customers/admin-customer.service';
import { StoreChainOtasErrors, CleanCurrentErrors } from 'app/states/otas/otas.actions';
import { StoreUserRole } from 'app/states/users-actions/users-actions.actions';
import { Customer } from 'app/shared/customer.model';

@Component({
  templateUrl: './session-theme.component.html',
  styleUrls: ['./session-theme.component.scss'],
  providers: [EmailsService, CustomerService],
  animations: [animation.collapsedState],
})
export class SessionThemeComponent extends LayoutsComponent implements OnInit, OnDestroy {
  currentUser;
  currentHotel;
  navbarMode = 'side';
  navbarOpen = true;
  section = '';
  isMobile = false;

  modal: NgbModalRef;

  sideBarCollapsed = false;
  animating = false;
  showFiller = false;
  availableLanguages: any[] = [];
  isMenuOpen = false;

  @ViewChild('sidenav', { static: true })
  elSidenav: MatSidenav;

  @ViewChild('mainContent', { read: ElementRef, static: true })
  public mainContent: ElementRef;

  @ViewChild('alertModal', { static: true })
  alertModalTemplate: TemplateRef<any>;

  @ViewChild('drawer')
  drawer: MatDrawer;

  suscription = new Subscription();
  currentChainIds = [];

  alertModalContent = {
    title: '',
    body: '',
    small: '',
    type: '',
    translateParams: {},
  };

  @Select(CustomersState.currentCustomer) currentCustomer$: Observable<any>;
  @Select(CustomersState.relativeCurrentChain) currentRelativeChain$: Observable<any>;
  currentCustomer: Customer;

  constructor(
    protected utilService: UtilService,
    private location: Location,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    private modalService: NgbModal,
    private observer: BreakpointObserver,
    private sessionService: SessionService,
    private createCaseDialogService: CreateCaseDialogService,
    private adminCustomerService: AdminCustomerService,
    private store: Store,
  ) {
    super(utilService);
    this.theme = 'session';
    const idGA = environment.googleAnalyticsKey;
    this.googleAnalyticsService.appendGaTrackingCode(idGA);
    this.currentUser = this.utilService.getCurrentUser();
    this.currentHotel = this.utilService.getCurrentHotel();
    this.currentCustomer = this.utilService.getCurrentCustomer();
    this.availableLanguages = this.utilService.translateLanguages;
    const languageSubscription = this.utilService
      .isLanguageChanged()
      .subscribe((lang) => (this.currentLanguage = lang));
    const customerSubscription = this.utilService.isCustomerChanged().subscribe((customer) => {
      this.storeCustomers(customer);
      this.fetchOtaErrors([customer]);
    });
    this.suscription.add(languageSubscription);
    this.suscription.add(customerSubscription);

    this.suscription.add(
      this.currentCustomer$.subscribe(() => {
        this.store.dispatch(new CleanCurrentErrors());
      }),
    );

    this.suscription.add(
      this.currentRelativeChain$.subscribe((chain) => {
        const chainIds = chain.map((hotel) => hotel.id);
        if (chain && chain.length > 0 && !this.chainsAreEquals(this.currentChainIds, chainIds)) {
          this.currentChainIds = chainIds;
          this.fetchOtaErrors(chain);
        }
      }),
    );
  }

  ngOnInit(): void {
    if (this.utilService.isMobile()) {
      this.navbarMode = 'over';
      this.navbarOpen = false;
    }
    this.initListeners();
    const customer = this.utilService.getCurrentHotel();
    this.fetchUserRole();
    this.storeCustomers(customer);
    this.fetchOtaErrors([this.currentCustomer]);
  }

  fetchUserRole() {
    this.sessionService.getCurrentUserRole(this.currentUser.id).subscribe((response: any) => {
      const role = response.user_role.name;
      this.store.dispatch(new StoreUserRole(role));
    });
  }

  storeCustomers(customer) {
    const userId = this.currentUser.id;
    this.sessionService.storeCustomers(customer, userId);
  }

  fetchOtaErrors(chain) {    
    const customersIds = chain.map((customer) => customer.id).join(',');
    const GOOGLE_ID = 30;
    const FACEBOOK_ID = 31;
    const TRIPADVISOR_ID = 1;
    const BOOKING_ID = 2;
    const otasIds = [GOOGLE_ID, FACEBOOK_ID, BOOKING_ID, TRIPADVISOR_ID].join(',');
    forkJoin({
      status: this.adminCustomerService.getChainOauthStatus(customersIds, otasIds),
      lastRead: this.adminCustomerService.getChainOtasLastRead(customersIds, otasIds),
    }).subscribe(this.handleResponseData.bind(this));
  }

  handleResponseData({ status, lastRead }) {    
     const chain = this.store.selectSnapshot(CustomersState.relativeCurrentChain).length > 0
       ? this.store.selectSnapshot(CustomersState.relativeCurrentChain) : this.fakeChain;

    if (status?.length > 0) {
      const errorsByCustomer = status.reduce((prev, curr) => {
        const customerFoundIndex = prev.findIndex(
          (statusByCustomer) => statusByCustomer?.customer?.id === curr.customer_id,
        );
        const { customer_id, ...status } = curr;
        const customer = chain.find((customer) => customer.id === customer_id);
        if (customerFoundIndex >= 0) {
          prev[customerFoundIndex].status.push(status);
          return [...prev];
        } else {
          return [
            ...prev,
            {
              customer,
              status: [status],
            },
          ];
        }
      }, []);
      this.storeOtasErrors(errorsByCustomer);
    }
  }

  storeOtasErrors(errorsByCustomer) {
    this.store.dispatch(new StoreChainOtasErrors(errorsByCustomer));
  }

  toogleNavbar() {
    this.elSidenav.toggle();
  }

  scrollTop(value = 0) {
    const to = value === 0 ? 0 : value + this.screenHeight - 200;
    this.mainContent.nativeElement.scrollTop = to;
  }

  openDialog(content, type = 'success') {
    this.modal = this.modalService.open(content, {
      windowClass: 'modal-alert modal-alert-' + type,
    });
  }

  closeDialog() {
    if (this.modal) this.modal.close();
  }

  handleMenuStatus(status) {
    if (status) {
      this.navbarMode = this.isMobile ? 'over' : 'side';
      this.elSidenav.open();
    } else {
      this.navbarMode = 'side';
      this.elSidenav.close();
    }
  }

  public get util() {
    return this.utilService;
  }

  get navState() {
    return this.sideBarCollapsed ? 'collapse' : 'expand';
  }

  start() {
    this.animating = true;
    this.tick();
  }

  done() {
    this.animating = false;
  }

  tick() {
    if (this.animating) requestAnimationFrame(() => this.tick());
  }

  initListeners() {
    this.suscription.add(
      this.utilService.isToggleNavbar().subscribe((value) => {
        this.sideBarCollapsed = value;
      }),
    );

    this.suscription.add(
      this.utilService.isScrollMainContent().subscribe((response) => {
        this.scrollTop(response);
      }),
    );

    this.suscription.add(
      this.router.events.subscribe(() => {
        if (this.location.path() !== '') {
          this.route = this.location.path();
        } else {
          this.route = 'Home';
        }
        this.setCurrentSection();
        this.section = this.utilService.currentSection.replace('/', '_');
      }),
    );

    this.suscription.add(
      this.utilService.hasAlerts().subscribe((response) => {
        this.alertModalContent.title = response.title;
        this.alertModalContent.body = response.message;
        this.alertModalContent.translateParams = response.translateParams;
        this.alertModalContent.small = response.smallMessage;
        this.alertModalContent.type = response.type;
        if (response.type) {
          this.openDialog(this.alertModalTemplate, response.type);
        } else {
          this.openDialog(this.alertModalTemplate, 'error');
        }
      }),
    );

    this.suscription.add(
      this.location.onUrlChange(() => {
        if (this.isMobile && this.elSidenav.opened) {
          this.navbarMode = 'over';
          this.elSidenav.close();
        }
      }),
    );

    this.suscription.add(
      this.observer.observe(['(max-width: 870px)']).subscribe((response) => {
        if (response.matches) {
          this.isMobile = true;
          this.navbarMode = this.isMobile ? 'over' : 'side';
          this.elSidenav.close();
        } else {
          this.isMobile = false;
          this.navbarMode = 'side';
          this.elSidenav.open();
        }
      }),
    );
  }

  public openNewModal() {
    this.utilService.ga('cases', 'click', `create-case-header`);
    this.createCaseDialogService.openModal(this.utilService.currentUser);
  }

  openMenuHandler() {
    this.drawer.open();
    this.isMenuOpen = true;
  }

  closeMenuMobile() {
    this.drawer.close();
    this.isMenuOpen = false;
  }

  switchLanguage(language: string) {
    this.utilService.setCurrrentLanguage(language);
    this.utilService.ga('language', 'click', language);
  }

  chainsAreEquals(currentChain: number[], newChain: number[]): boolean {
    if (currentChain.length !== newChain.length) {
      return false;
    }
    return currentChain.every((element, index) => element === newChain[index]);
  }

  ngOnDestroy(): void {
    this.suscription.unsubscribe();
  }

  get isRetail() {
    return this.utilService.customerIsRetail;
  }

  get fakeChain() {
    return [this.store.selectSnapshot(CustomersState.currentCustomer)];
  }
}
