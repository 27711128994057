import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { animation } from '../utils/animation-custom';
import { NotificationsService } from 'app/shared/notifications/services/notifications.service';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { DefaultLanguageState } from 'app/states/default-language/default-language.state';
import { Select } from '@ngxs/store';
import { environment } from 'src/environments/environment';
import { ModalService } from 'app/core-lib/dialogs/modal/modal.service';
import { CoreLibNotificationsPopupModalComponent } from 'app/core-lib/dialogs/notifications-popup-modal/notifications-popup-modal.component';
import { APIService } from 'app/graphql-api.service';
import { AdminAlertsService } from 'app/admin/alerts/admin-alerts.service';
import { UtilService } from '../util.service';
import { SessionService } from 'app/_services';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';

@Component({
  selector: 'mh-header-button',
  templateUrl: './header-button.component.html',
  styleUrls: ['./header-button.component.scss'],
  animations: [animation.rotate],
})
export class HeaderButtonComponent implements OnInit, OnDestroy {
  @Select(DefaultLanguageState) languageReady$: Observable<{ code: string; languageId: number }>;

  @Input()
  type: 'searcher' | 'cases' | 'support' | 'languages' | 'notifications' | 'menu' = 'searcher';

  @Input()
  currentUser;

  @Input()
  userProfile;

  @Input()
  isRetail = false;

  @Input()
  customer;

  @Input()
  currentLanguage;

  @Input()
  currentLanguageId;

  @Input()
  availableLanguages = [];

  @Input()
  size = 40;

  @Input()
  isMobile = false;

  #detectedCases = [];
  @Input() set detectedCases(value) {
    this.#detectedCases = value;
    this.total = value ? value.reduce((acc, curr) => acc + curr.detected_cases, 0) : 0;
  }

  get detectedCases() {
    return this.#detectedCases;
  }

  @Output()
  updated: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  isReady: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  gaEvent = new EventEmitter<any>();

  @Output()
  updatedLanguage = new EventEmitter<any>();

  @Output()
  createCaseEvent = new EventEmitter<any>();

  @Output()
  openMenuEvent = new EventEmitter<any>();

  @ViewChild('triggerMenu')
  menu: MatMenuTrigger;

  private showingInput = false;
  activeLanguageBtn = false;
  activeSupportbtn = false;
  activeUserBtn = false;
  activeNotiBtn = false;
  searcherMenuOpened = false;
  isOpen = false;
  newsData;
  alertsData;
  subscriptions = new Subscription();
  notificationComponentType = 'menu';
  hasMoreDataAlerts = false;
  hasMoreDataNews = false;
  pageSizeNews = 10;
  pageSizeAlerts = 10;
  newsUrl: string;
  alertsUrl: string;
  languageId;
  obsLangId;
  total = 0;
  products = {
    1: 'Followup',
    2: 'Onsite',
    3: 'Online',
  };

  constructor(
    private notificationsService: NotificationsService,
    private modalService: ModalService,
    private graphQlService: APIService,
    private adminAlertsService: AdminAlertsService,
    private utilService: UtilService,
    private sessionService: SessionService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    if (this.isNotifications) {
      this.getChannels();
      this.newsUrl = `${environment.notificationsPath}notification-news/users/${this.currentUser.id}/language/${this.currentLanguageId}`;
      this.alertsUrl = `${environment.notificationsPath}notification-alerts/users/${this.currentUser.id}/language/${this.currentLanguageId}`;
      this.getAllCommunications();
      this.initLanguageSubscriber();
    }
  }

  async getNews() {
    try {
      this.newsData = await this.notificationsService.getNotificationsData({ page: 0 }, this.newsUrl).toPromise();
    } catch (error) {
      this.newsData = [];
      console.error(error);
    }
  }

  async handleShowMore(type: string) {
    const url = type === 'alert' ? this.alertsUrl : this.newsUrl;
    if (type === 'alert') {
      this.pageSizeAlerts += 10;
    } else {
      this.pageSizeNews += 10;
    }
    const pageSize = type === 'alert' ? this.pageSizeAlerts : this.pageSizeNews;

    try {
      const res = await this.notificationsService.getNotificationsData({ page: 0, pageSize }, url).toPromise();
      if (res && res.info) {
        if (type === 'alert') {
          this.alertsData = res.info;
          this.hasMoreDataAlerts = res.has_next;
        } else {
          this.newsData = res.info;
          this.hasMoreDataNews = res.has_next;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  getAllCommunications() {
    forkJoin([
      this.notificationsService.getNotificationsData({ page: 0 }, this.newsUrl),
      this.notificationsService.getNotificationsData({ page: 0 }, this.alertsUrl),
    ]).subscribe(([news, alerts]) => {
      this.newsData = news.info;
      this.hasMoreDataAlerts = alerts.has_next;
      this.alertsData = alerts.info;
      this.hasMoreDataNews = news.has_next;
    });
  }

  async getCommunicationsByType(isAlert: boolean) {
    try {
      const url = isAlert ? this.alertsUrl : this.newsUrl;
      const pageSize = isAlert ? this.pageSizeAlerts : this.pageSizeNews;
      const res = await this.notificationsService.getNotificationsData({ page: 0, pageSize }, url).toPromise();
      if (res && res.info) {
        if (isAlert) {
          this.alertsData = res.info;
          this.hasMoreDataAlerts = res.has_next;
        } else {
          this.newsData = res.info;
          this.hasMoreDataNews = res.has_next;
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  initLanguageSubscriber() {
    this.subscriptions.add(
      this.languageReady$.subscribe(async (languageCode) => {
        if (
          languageCode.languageId &&
          this.languageId !== languageCode.languageId &&
          this.obsLangId !== languageCode.languageId
        ) {
          this.obsLangId = languageCode.languageId;
          this.languageId = languageCode.languageId;
          this.currentLanguageId = languageCode.languageId;
          this.newsUrl = `${environment.notificationsPath}notification-news/users/${this.currentUser.id}/language/${this.currentLanguageId}`;
          this.alertsUrl = `${environment.notificationsPath}notification-alerts/users/${this.currentUser.id}/language/${this.currentLanguageId}`;
          this.getAllCommunications();
          this.openNewsPopup(); // Call the openNewsPopup method when news data is available
        }
      }),
    );

    this.subscriptions.add(
      this.notificationsService.listenUpdateOnNews().subscribe(() => {
        this.getCommunicationsByType(false);
      }),
    );
  }

  async getChannels() {
    try {
      const resp = await this.notificationsService.getChannels(this.customer.id, this.currentUser.id).toPromise();
      if (resp) {
        this.initPubSub(resp.data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // New implementation of pubsub
  initPubSub(channels: { channel_id: string; channel_identifier: string }[] = []) {
    channels.forEach((channel) => {
      this.subscriptions.add(
        this.graphQlService.OnMessagePostedListener(channel.channel_id).subscribe((response) => {
          if (response) {
            const isAlerts = response.data.onMessagePosted.content === 'refresh-notifications-alerts' ? true : false;
            switch (response.data.onMessagePosted.content) {
              case 'refresh-notifications-alerts':
                this.getCommunicationsByType(isAlerts);
                break;
              case 'sync-alerts':
                this.syncAlerts();
                break;
              default:
                break;
            }
          }
        }),
      );
    });
  }

  private syncAlerts() {
    this.adminAlertsService.refreshAlerts();
    this.adminAlertsService
      .getAlert(this.utilService.currentHotel.id, this.utilService.getLanguageId())
      .subscribe((response: any) => {
        this.sessionService.setResponseAlert(response);
      });
  }

  toggleShowInput(origin: string) {
    this.showingInput = !this.showingInput;
    if (origin) {
      this.searcherMenuOpened = false;
    }
  }

  toggleRotateIcon() {
    this.showingInput = !this.showingInput;
  }

  setFocusSearchInput() {
    this.searcherMenuOpened = true;
  }

  toggleMenu() {}

  updateLanguage($event) {
    this.updatedLanguage.emit($event);
  }

  customerUpdated($event) {
    this.menu.closeMenu();
    this.updated.emit($event);
  }

  readyToEmit($event) {
    this.isReady.emit($event);
  }

  ga($event) {
    this.gaEvent.emit($event);
  }

  createCase() {
    this.utilService.ga('', ' new-case-header');
    this.createCaseEvent.emit(true);
  }

  openSideMenu() {
    this.openMenuEvent.emit(true);
  }

  handleHistoryBtn() {
    this.isOpen = !this.isOpen;
    this.activeNotiBtn = !this.activeNotiBtn;
  }

  clickCNBtn() {
    this.activeNotiBtn = !this.activeNotiBtn;
    this.isOpen = !this.isOpen;
    this.pageSizeAlerts = 10;
    this.pageSizeNews = 10;
    if (this.alertsData?.length > 10) {
      this.alertsData.splice(10);
      this.hasMoreDataAlerts = true;
    }

    if (this.newsData?.length > 10) {
      this.newsData.splice(10);
      this.hasMoreDataNews = true;
    }
  }

  async openNewsPopup() {
    const popupData = this.newsData.filter(
      (newData) => newData.notifications_type === 'POPUP' && newData.notifications_status === 'UNREAD',
    );
    if (popupData.length === 0) return;

    const classes = ['lg', 'overlay-panel', 'fit-height-content', '!tw-p-0'];
    const config = {
      data: {
        noCloseOnBackdropClick: true,
        timer: true,
        news: popupData,
        currentUser: this.currentUser,
        currentCustomerId: this.customer.id,
      },
    };
    this.modalService.open(CoreLibNotificationsPopupModalComponent, config, classes);
  }

  onProductClick(productId) {
    const page = productId === 3 ? 'resenas' : 'huespedes';
    const url = `/${this.products[productId].toLowerCase()}/${page}`;
    const startDate = DateTime.now().minus({ days: 30 }).toFormat('yyyy-LL-dd');
    const endDate = DateTime.now().toFormat('yyyy-LL-dd');
    const queryParams = { detectedCases: true, startDate, endDate };
    const urlTree = this.router.createUrlTree(url.split('/'), { queryParams });
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigateByUrl(urlTree);
    });
  }

  getWidth(): string {
    const baseWidth = 128;
    const maxWidth = 150;
    const maxTotal = 100;

    const newWidth = baseWidth + (Math.min(this.total, maxTotal) * (maxWidth - baseWidth)) / maxTotal;
    return `${newWidth}px`;
  }

  ngOnDestroy(): void {
    this.pageSizeAlerts = 10;
    this.pageSizeNews = 10;
    this.subscriptions.unsubscribe();
  }

  get mobileClassSearcher() {
    return this.isMobile ? 'mobile' : '';
  }

  get showInput() {
    return this.showingInput;
  }

  get rotateAnimationState() {
    return !this.showingInput ? '0' : '-180';
  }

  get isSearcher() {
    return this.type === 'searcher';
  }

  get isCases() {
    return this.type === 'cases';
  }

  get isLanguages() {
    return this.type === 'languages';
  }

  get isSupport() {
    return this.type === 'support';
  }

  get isNotifications() {
    return this.type === 'notifications';
  }

  get isMenu() {
    return this.type === 'menu';
  }

  get sizeBtn() {
    return {
      'width.px': `${this.size}`,
      'height.px': `${this.size}`,
    };
  }

  isActive(language: any) {
    return language === this.currentLanguage;
  }

  get linkSupport() {
    const links = {
      es: 'https://soporte.myhotel.cl/es/base-conocimientos',
      en: 'https://soporte.myhotel.cl/en/base-conocimientos',
      pt: 'https://soporte.myhotel.cl/pt-br/base-conocimientos',
    };
    return links[this.currentLanguage];
  }
}
