import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { SessionService } from 'app/_services';
import { ModalService } from 'app/core-lib/dialogs/modal/modal.service';
import { CoreLibOtasIntegrationStatusModalComponent } from 'app/core-lib/dialogs/otas-integration-status-modal/otas-integration-status-modal.component';
import { CoreLibPmsIntegrationStatusModalComponent } from 'app/core-lib/dialogs/pms-integration-status-modal/pms-integration-status-modal.component';

enum MenuContext {
  GENERAL = 'general',
  CORPORATIVE = 'corporative',
}

@Component({
  selector: 'mh-core-lib-integration-status',
  templateUrl: './integration-status.component.html',
  styleUrls: ['./integration-status.component.scss'],
})
export class CoreLibIntegrationStatusComponent implements OnChanges {
  @Input()
  currentHotel = null;

  @Input()
  otasErrors = [];

  @Input()
  simpleOtasErrors;

  @Input()
  currentLanguage = 'es';

  @Input()
  menuContext: MenuContext.GENERAL | MenuContext.CORPORATIVE = MenuContext.GENERAL;

  @Input()
  loading = true;

  @Input()
  sideBarCollapsed;

  @Input()
  statusNavbar;

  @Output()
  integrationButtonClicked = new EventEmitter();

  areOtasErrors = false;

  constructor(private modalService: ModalService, private sessionService: SessionService) {}

  ngOnChanges() {
    this.setErrors();
  }

  setErrors() {
    if (this.menuContext === MenuContext.CORPORATIVE) {
      this.areOtasErrors = this.otasErrors?.some((errorsByCustomer) =>
        errorsByCustomer.status.some((statusByOta) => statusByOta.status === 'red'),
      );
    } else {
      this.areOtasErrors =
        this.simpleOtasErrors && this.simpleOtasErrors.length > 0
          ? this.simpleOtasErrors.some((status) => status.error)
          : this.otasErrors?.find(
              (errorsByCustomer) =>
                errorsByCustomer.customer.id === this.sessionService.currentCustomer.id &&
                errorsByCustomer.status.some((status) => !status.configured),
            ) || false;
    }
  }

  handlePmsClick() {
    const classes = ['no-padding', 'overlay-panel', 'sm'];
    const config = {
      data: {
        lastUpdate: this.lastUpdate,
        currentLanguage: this.currentLanguage,
      },
    };
    this.modalService.open(CoreLibPmsIntegrationStatusModalComponent, config, classes);
  }

  handleOtasClick() {
    const classes = ['no-padding', 'overlay-panel'];
    if (this.menuContext === MenuContext.GENERAL) {
      classes.push('sm');
    }
    const otasErrors =
      this.menuContext === MenuContext.GENERAL
        ? this.otasErrors?.filter(
            (statusByCustomer) => statusByCustomer.customer.id === this.sessionService.currentCustomer.id,
          )
        : this.otasErrors;        
    const config = {
      data: {
        otasErrors,
        menuContext: this.menuContext,
      },
    };
    const connectAction = this.modalService.open(CoreLibOtasIntegrationStatusModalComponent, config, classes);
    connectAction.afterClosed().subscribe((action) => {
      this.integrationButtonClicked.emit(action);
    });
  }

  get isIntegrationStatusOk() {
    return this.sessionService.currentCustomer?.integration_status_dto?.flag_ok;
  }

  get lastUpdate() {
    return this.sessionService.currentCustomer?.integration_status_dto.last_update;
  }
}
